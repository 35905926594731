import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CursorType } from "types/projectTypes";

const initialState = {
  type: CursorType.default,
};

const cursorSlice = createSlice({
  name: "cursor",
  initialState,
  reducers: {
    setCursorType(state, action: PayloadAction<CursorType>) {
      state.type = action.payload;
    },
  },
});

export const { setCursorType } = cursorSlice.actions;
export default cursorSlice.reducer;

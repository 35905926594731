import { motion, useScroll, useSpring } from "framer-motion";
import HorizontalCarousel from "./horizontalCarousel";
import { Project } from "types/projectTypes";

interface CarouselWrapperProps {
  projects: Project[];
  animationStarted: boolean;
  handleStartAnimate: () => void;
}


const CarouselWrapper = ({
  projects,
  animationStarted,
  handleStartAnimate,

}:CarouselWrapperProps) => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });


  return(
    <motion.div
    initial={{ scale: 0.5 }}
    transition={{ duration: 1.2, delay: 0.5, ease: "easeInOut" }}
    animate={animationStarted ? { scale: 1 } : {}}
    className={` flex flex-col gap-10 w-full snap-y snap-mandatory snap-always`}
  >
    {projects.sort((a,b)=>a.project_order - b.project_order).map((project, idx) => {
      if(idx === 0) {
      return (
        <HorizontalCarousel
          handleStartAnimate={handleStartAnimate}
          key={idx}
          project={project}
        />
      );
      }
      else{
        return (
          <HorizontalCarousel
            key={idx}
            project={project}
          />
        );
      }
    })}
    <motion.div className="progress opacity-30 blur-sm " style={{ scaleX }} />
  </motion.div>

  )
 
};

export default CarouselWrapper;

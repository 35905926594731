import { DisplayType } from "utils/coverFunction";

export interface Project {
  id: number;
  title: string;
  description: string;
  baseImageUrl: string;
  logoUrl: string;
  project_order: number;
  logo_img_optimized: string;
  base_img_original: string;
  base_img_small: string;
  base_img_tiny: string;
}

export interface ImageProps {
  id: number;
  typeView: string;
  description?: string;
  link?: string;
  imageUrl: string;
}

export interface ProjectReturnTypes {
  base_img_url: string;
  description: string;
  id: number;
  logo_img_url: string;
  title: string;
  project_order?: number;
  logo_img_optimized: string;
  base_img_original: string;
  base_img_small: string;
  base_img_tiny: string;
}

export interface ContentReturnTypes {
  webp_original_image: string;
  webp_small_image: string;
  webp_tiny_image: string;
  is_vertical_img: boolean;
  id: number;
  title: string;
  display_type: DisplayType;
  image_url: string;
  order: number;
  project: number;
  description: string;
  ext_img_url: string;
  original_image: string;
  small_image: string;
  tiny_image: string;
}

export enum CursorType {
  default = "default",
  pointer = "pointer",
  left = "left",
  right = "right",
}

export function convertDropboxUrlToProxyUrl(dropboxUrl: string): string {
  const dropboxBase = "https://www.dropbox.com/";
  const proxyBase = "https://beyond-studio.co/proxy/";

  // Ensure the URL starts with the Dropbox base URL
  if (dropboxUrl.startsWith(dropboxBase)) {
    // Get the part of the URL after the Dropbox base
    const restOfUrl = dropboxUrl.substring(dropboxBase.length);

    // Return the full proxy URL
    return proxyBase + restOfUrl;
  }

  // If the URL doesn't start with Dropbox's base URL, return it unchanged
  throw new Error("Invalid Dropbox URL");
}

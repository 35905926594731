const getHeightBasedOnWindowSize = () => {
  const windowWidth = window.innerWidth;
  return windowWidth < 1280; // 1280px corresponds to the "xl" breakpoint in Tailwind CSS
};

export const projectVariant = {
  opened: {
    height: getHeightBasedOnWindowSize() ? "50vh" : "60vh",
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
  closed: {
    height: getHeightBasedOnWindowSize() ? "33vh" : "40vh",
    width: getHeightBasedOnWindowSize() ? "100%" : "33vh",

    // Add closed animation if necessary
  },
};

export const asideVariant = {
  opened: {
    width: getHeightBasedOnWindowSize() ? "8rem" : "20rem",
    backgroundColor: "white",
    boxShadow: "0 0 10px 10px white",
    transition: {
      duration: 1,
      delay: 0.5,
    },
  },
  closed: {
    width: getHeightBasedOnWindowSize() ? "16.666%" : "25%",
    backgroundColor: "transparent",
    transition: {
      duration: 0,
    },
  },
};

// Optional: Update height on window resize (if needed)
window.addEventListener("resize", () => {
  // const isSmallerThanXl = getHeightBasedOnWindowSize();
  // projectVariant.opened.height = isSmallerThanXl ? "50vh" : "60vh";
  // asideVariant.opened.width = isSmallerThanXl ? "8rem" : "20rem";
});

export const contentVariant = {
  opened: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,

      ease: "easeInOut",
    },
  },
  closed: {
    x: "-50%",
    opacity: 0,
    transition: {
      // delay: 0.5,
      duration: 0.4,
    },
  },
};

export const asideContentVariant = {
  opened: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.5,
      ease: "easeInOut",
    },
  },
  closed: {
    x: "-50%",

    opacity: 0,
    transition: {
      duration: 0.5,
      delay: 0.5,
      ease: "easeInOut",
    },
  },
};

export const closeVariant = {
  opened: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
    },
  },
};

import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import useMobile from "hooks/useMobile";
import { flipVariants } from "./wordFlipper";
const words = ["IMAGINATION", "EXPECTATION", "VISION", "STUDIO"];

const WordAnimation = () => {
  const [index, setIndex] = React.useState(0);
  const [hover, setHover] = React.useState(false);
  const isMobile = useMobile();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % words.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="flex flex-col md:flex-row pt-1 items-center justify  center h-full text-nowrap pl-2 w-1/3 min-w-[30%] max-w-[30%]  "
    >
      <img
        className="inline-block h-1/2 "
        src="./beyond-clear.png"
        alt="letter b"
      />

      <div className="text-sm pt-1 -ml-2 md:ml-1 md:text-4xl font-bold flex flex-col md:flex-row shrink-0  md:min-w-[450px]   w-24 md:w-fit items-center text-white">
        <AnimatePresence mode="wait">
          <motion.div
            key={words[index]}
            variants={flipVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {words[index].split("").map((letter, idx) => (
              <motion.span
                key={idx}
                initial={{ rotateY: 90, opacity: 0 }}
                animate={{ rotateY: 0, opacity: 1 }}
                exit={{ rotateY: -90, opacity: 0 }}
                transition={{ duration: 0.6 }}
                className={`inline-block ml-1 mt-1 ${
                  index === 3 ? "text-black" : "text-base-content"
                }   shrink-0 GOST-S1 text-xs md:text-4xl`}
              >
                {letter}
              </motion.span>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default WordAnimation;

import { motion } from "framer-motion";
import { ChevronDownIcon } from "lucide-react";
import { RefObject, useEffect, useRef, useState } from "react";

 const ScrollIcon = ({ cardRef }: { cardRef: RefObject<HTMLElement> }) => {
    const [showIcon, setShowIcon] = useState(false);
    const [iconYPosition, setIconYPosition] = useState(0);
    const wasRendered = useRef(false);
  
    useEffect(() => {
      const card = cardRef.current;
      const handleScroll = () => {
        if (card) {
          const { scrollTop, scrollHeight, clientHeight } = card;
          const iconPosition = scrollTop + clientHeight - 30; // Adjust -30 to position the icon above the bottom
          setIconYPosition(iconPosition);
  
          if (scrollTop + clientHeight >= scrollHeight - 10) {
            setShowIcon(false);
          } else {
            setShowIcon(true);
          }
        }
      };
      if (!wasRendered.current) {
        wasRendered.current = true;
        handleScroll();
      }
      card?.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
      handleScroll(); // Initial call to set position on load
  
      return () => {
        card?.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      };
    }, [cardRef]);
  
    return (
      <>
        {showIcon && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{
              top: iconYPosition,
              transition: { duration: 0, repeat: Infinity },
            }}
            exit={{ opacity: 0 }}
            className="absolute left-1/2 transform -translate-x-1/2"
            // style={{ top: `${iconYPosition}px` }}
          >
            <ChevronDownIcon className="h-6 w-6 z-10 text-black animate-bounce" />
          </motion.div>
        )}
      </>
    );
  };
  export default ScrollIcon;
// import { projectVariant } from "./carouselAnimation";
import { AnimatePresence, motion } from "framer-motion";
import { Project } from "types/projectTypes";
import DescriptionAside from "components/aside/descriptionAsideNew";
import ActionButtons from "./actionCarouselButtons";
import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { convertDropboxUrlToProxyUrl } from "utils/utilsFunctions";
import ResponsiveImage from "components/img/responsiveImage";

interface HorizontalCarouselViewProps {
  project: Project;
  isOpened: boolean;
  handleClick: () => void;
  projectRef: React.MutableRefObject<HTMLDivElement | null>;
  baseImageRef: React.MutableRefObject<HTMLDivElement | null>;
  children?: React.ReactNode;
  carouselRef: React.MutableRefObject<HTMLDivElement | null>;
  handleScroll: (direction: "prev" | "next") => void;
  canScrollLeft: boolean;
  canScrollRight: boolean;
  isMobile: boolean;
}

const CarouselContainer = styled.div`
  /chrome compatability */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /*firefox compatability*/
  scrollbar-width: none; 

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

  }
`;

const HorizontalCarouselView = ({
  project,
  isOpened,
  handleClick,
  projectRef,
  baseImageRef,
  children,
  carouselRef,
  handleScroll,
  canScrollLeft,
  canScrollRight,
  isMobile,
}: HorizontalCarouselViewProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [logoSrc, setLogoSrc] = useState(project.logo_img_optimized);
  const fixedLogoUrl = convertDropboxUrlToProxyUrl(project.logoUrl);
  const fixedBaseImageUrl = convertDropboxUrlToProxyUrl(project.baseImageUrl);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const widthSize: string = useMemo(() => {
    if (windowWidth < 768) {
      return "small";
    }
    if (windowWidth < 1024) {
      return "medium";
    }
    return "large";
  }, [windowWidth]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const projectVariant = {
    openedLarge: {
      height: "60vh",
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
    openedSmall: {
      height: "50vh",
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
    closed: {
      height: "",
      transition: {
        duration: 1,
        delay: 0.8,
      },
      // height: "33vh",
    },
  };

  return (
    <motion.div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      variants={isMobile ? undefined : projectVariant}
      onAnimationComplete={()=>{
        projectRef.current?.scrollIntoView({behavior: "smooth"})
      }}
      animate={
        isOpened
          ? widthSize === "large"
            ? "openedLarge"
            : "openedSmall"
          : "closed"
      }
      initial="closed"
      id="project-container"
      className={`h-[33vh] w-full  px-2  gap-4 flex justify-center relative overflow-hidden`}
      ref={projectRef}
    >
      {isMobile ? (
        <div className="absolute right-2 top-0 z-[100]">
          <img
            className="h-16 backdrop-blur-sm aspect-square"
            src={logoSrc}
            alt="project logo"
            onError={() => setLogoSrc(fixedLogoUrl)} // Fallback to fixedLogoUrl if optimized image fails
          />
        </div>
      ) : (
        <>
          <ActionButtons
            handleScroll={handleScroll}
            handleClose={handleClick}
            canScrollLeft={canScrollLeft}
            canScrollRight={canScrollRight}
            isOpened={isOpened}
          />
          <DescriptionAside project={project} isOpened={isOpened} />
        </>
      )}

      <CarouselContainer
        // onClick={() => isMobile && handleScroll("next")}
        id="costume-carousal"
        className="flex flex-grow gap-5 overflow-y-hidden  overflow-x-scroll snap-x snap-always snap-mandatory"
        ref={carouselRef}
      >
        <div
          ref={baseImageRef}
          onClick={
            !isOpened
              ? handleClick
              : isMobile
              ? () => handleScroll("next")
              : undefined
          }
          className={`h-full ${
            isMobile ? "" : "min-w-[33vh]"
          }  aspect-video shrink-0 z-10  snap-start ${
            !isOpened &&
            "cursor-pointer hover:scale-105 absolute hover:shadow-xl transition-all duration-500"
          }`}
          id="project-content"
        >
          <ResponsiveImage
            original_image={project.base_img_original || ""}
            small_image={project.base_img_small || ""}
            tiny_image={project.base_img_tiny || ""}
            fallback_image={project.baseImageUrl || ""}
            isVertical={false}
          />
        
          {!isOpened && (
            <motion.p
              initial={{ x: 0 }}
              animate={
                isHovered && !isOpened
                  ? { x: 0, zIndex: 0, opacity: 1, translateX: '-105%' }
                  : { x: 100, zIndex: -1, opacity: 0, translateX:'-105%' }
              }
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="absolute left-0  top-[5rem] BOYA-regular text-4xl  "
            >
              {project.title}
            </motion.p>
          )}
        </div>

        <AnimatePresence mode="wait">
          {isOpened && <>{children}</>}
        </AnimatePresence>
      </CarouselContainer>
    </motion.div>
  );
};

export default HorizontalCarouselView;

import { motion } from "framer-motion";
import { closeVariant } from "../carousel/carouselAnimation";
import { RiCloseLargeFill } from "react-icons/ri";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

type ButtonIcon = "close" | "left" | "right";

interface CircleBlurButtonProps {
  handleClick: () => void;
  isOpened: boolean;
  icon: ButtonIcon;
  className?: string;
}

const iconSelector = {
  close: () => (
    <RiCloseLargeFill className="w-full h-full cursor-pointer " color="#B5C0D0" />
  ),
  left: () => (
    <GoChevronLeft className="w-full h-full cursor-pointer " color="#B5C0D0" />
  ),
  right: () => (
    <GoChevronRight className="w-full h-full cursor-pointer " color="#B5C0D0" />
  ),
};

const CircleBlurButton = ({
  handleClick,
  isOpened,
  icon,
  className,
}: CircleBlurButtonProps) => {
  return (
    <motion.div
      variants={closeVariant}
      animate={isOpened ? "opened" : "closed"}
      initial="closed"
      exit='closed'
      style={{ boxShadow: "0 0 10px 2px rgba(0,0,0,0.1)" }}
      className={` ${className} absolute w-10 h-10 p-2 rounded-full backdrop-blur-xl  z-50  hover:scale-125 transition-all duration-300 ease-out hover:border-2 hover:border-white hover:border-solid`}
    >
      <div onClick={handleClick} className="w-full h-full">
        {iconSelector[icon]()}
      </div>
    </motion.div>
  );
};
export default CircleBlurButton;

import { motion } from "framer-motion";
import {
  asideContentVariant,
  // asideVariant,
} from "../carousel/carouselAnimation";
import { Project } from "types/projectTypes";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import ScrollIcon from "../scrollBar/ScrollIcon";
import { useEffect, useMemo, useRef, useState } from "react";
import { convertDropboxUrlToProxyUrl } from "utils/utilsFunctions";

interface DescriptionAsideProps {
  project: Project;
  isOpened: boolean;
}
const DescriptionAside = ({ project, isOpened }: DescriptionAsideProps) => {
  const cardRef = useRef<HTMLElement>(null);
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  const logoFixedUrl = convertDropboxUrlToProxyUrl(project.logoUrl);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let times: NodeJS.Timeout;

  const widthSize: string = useMemo(() => {
    if (windowWidth < 768) {
      return "small";
    }
    if (windowWidth < 1024) {
      return "medium";
    }
    return "large";
  }, [windowWidth]);

  useEffect(() => {
    if (isOpened) {
      times = setTimeout(() => {
        setShowScrollIcon(true);
      }, 2500);
    } else {
      setShowScrollIcon(false);
    }

    return () => {
      clearTimeout(times);
    };
  }, [isOpened]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  const asideVariant = {
    openedLarge: {
      width: "20rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px 10px white",
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
    openedSmall: {
      width: "8rem",
      backgroundColor: "white",
      boxShadow: "0 0 10px 10px white",
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
    closedLarge: {
      width: "25%",

      backgroundColor: "transparent",

      transition: {
        duration: 1,
      },
    },
    closedSmall: {
      width: "16.666%",

      backgroundColor: "transparent",

      transition: {
        duration: 1,
      },
    },
  };
  return (
    <motion.aside
      variants={asideVariant}
      ref={cardRef}
      animate={
        isOpened
          ? widthSize === "medium"
            ? "openedSmall"
            : "openedLarge"
          : widthSize === "medium"
          ? "closedSmall"
          : "closedLarge"
      }
      initial="closed"
      className={`w-[5rem] md:w-1/6  xl:w-1/4 h-full  relative shrink-0 no_scrollbar overflow-x-hidden ${
        isOpened ? "overflow-y-scroll" : "overflow-y-hidden"
      }`}
      id="project-aside"
    >
      <header className="h-fit max-h-[120px] w-full flex justify-end relative ">
        <img
          className=" h-[5rem]  aspect-square"
          src={logoFixedUrl}
          alt="project logo"
        />
      </header>

      <motion.main
        variants={asideContentVariant}
        animate={isOpened ? "opened" : "closed"}
        initial="closed"
        className="text-end break-words text-pretty md:mr-6 "
      >
        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <p className="text-lg xl:text-xl GOST" {...props} />
            ),
          }}
          remarkPlugins={[remarkBreaks]}
        >
          {project.title}
        </ReactMarkdown>

        <ReactMarkdown
          components={{
            p: ({ node, ...props }) => (
              <p
                className="text-xs font-extralight sm:text-base xl:text-sm GOST"
                {...props}
              />
            ),
          }}
          remarkPlugins={[remarkBreaks]}
        >
          {project.description}
        </ReactMarkdown>
      </motion.main>
      {showScrollIcon && <ScrollIcon cardRef={cardRef} />}
    </motion.aside>
  );
};
export default DescriptionAside;

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import cursorReducer, { setCursorType } from "./slices/cursorSlice";

import { projectApi } from "./api/projectApi";

const rootReducer = {
  cursor: cursorReducer,
  [projectApi.reducerPath]: projectApi.reducer,
};

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      projectApi.middleware
    ),
});

setupListeners(store.dispatch);

// Export the store and the actions ========================
export { store, setCursorType };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store/index";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <div className="w-full h-full relative  ">
      <App />
    </div>

    {/* <Footer title="Beyond Studio" /> */}
  </Provider>
);

reportWebVitals();
//skelton

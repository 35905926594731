import React, { useState } from "react";
import WordAnimation from "components/animation/wordAnimation";
import { motion } from "framer-motion";
import useMobile from "hooks/useMobile";
import PickerAnimation from "components/animation/pickerAnimation"; // Ensure you import the Example component
import { IoMdMenu } from "react-icons/io";
interface NavBarProps {
  onViewChange: (index: number) => void;
}

const NavBar = ({ onViewChange }: NavBarProps) => {
  const isMobile = useMobile();
  const [visibleExample, setVisibleExample] = useState(0);

  const handleClick = (index: any) => {
    onViewChange(index);
    setVisibleExample(index);
  };

  return (
    <div
      className={` ${
        isMobile ? "h-12" : "h-16 backdrop-blur-sm"
      } w-full nav-bg  top-0 z-[1000] flex justify-between fixed`}
    >
      <WordAnimation />
      {isMobile ? (
        <div>
          <NavI
            labels={["Projects", "About", "Contact"]}
            visibleExample={visibleExample}
            handleClick={handleClick}
          />
        </div>
      ) : (
        <div className="w-1/2 flex justify-center gap-1 mr-2 ">
          <NavItems visibleExample={visibleExample} handleClick={handleClick} />
        </div>
      )}

      {/* <div className="w-1/3"></div> */}
    </div>
  );
};

export default NavBar;

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const Example = () => (
  <motion.ul
    className="container"
    variants={container}
    initial="hidden"
    animate="visible"
  >
    {[0, 1, 2, 3].map((index) => (
      <motion.li key={index} className="item" variants={item} />
    ))}
  </motion.ul>
);

const NavI = ({
  labels,
  visibleExample,
  handleClick,
}: {
  labels?: string[];
  visibleExample: number;
  handleClick: (index: number) => void;
}) => {
  return (
    <div className="drawer drawer-end  ">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        {/* Page content here */}
        <label
          htmlFor="my-drawer-4"
          className="drawer-button btn btn-ghost hover:bg-transparent"
        >
          <IoMdMenu className="h-full w-10" />
        </label>
      </div>
      <div className="drawer-side ">
        <label
          htmlFor="my-drawer-4"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu bg-white   text-black   min-h-full w-60 p-2">
          {/* Sidebar content here */}
          <h3 className=" mb-5 BOYA-bold text-[2rem] text-zinc-600 menu-title">
            Beyond Studio
          </h3>
          {labels?.map((label, index) => (
            <li
              key={index}
              className="flex   items-start h-10  justify-center "
            >
              {/* {visibleExample === index && <PickerAnimation />} */}
              <label className="h-10" htmlFor="my-drawer-4">
                <p
                  className={`GOST ${
                    visibleExample === index && " font-bold btn-link"
                  }   text-xs text-black text-nowrap md:text-lg btn-xs  cursor-pointer`}
                  onClick={() => handleClick(index)}
                >
                  {label}
                </p>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const NavItems = ({
  visibleExample,
  handleClick,
}: {
  visibleExample: number;
  handleClick: (index: number) => void;
}) => {
  return (
    <>
      {["Projects", "About", "Contact"].map((item, index) => (
        <div key={index} className="grid grid-cols-3  justify-items-center lg:justify-items-start place-items-end ">
          <PickerAnimation visible={visibleExample === index} />
          <p
            className="GOST text-zinc-700 col-span-2  translate-y-[50%] text-xs text-nowrap md:text-sm  cursor-pointer"
            onClick={() => handleClick(index)}
          >
            {item}
          </p>
        </div>
      ))}
    </>
  );
};

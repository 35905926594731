import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { None } from "framer-motion";
import { ContentReturnTypes, ProjectReturnTypes } from "types/projectTypes";

export const projectApi = createApi({
  reducerPath: "projectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `/api`,
  }),
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectReturnTypes[], null>({
      query: () => ({
        url: `/project`,
        method: "GET",
      }),
    }),
    getProjectContent: builder.query<ContentReturnTypes[], number>({
      query: (id) => ({
        url: `/project-content/get_by_project/?project_id=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectContentQuery,
  useLazyGetProjectContentQuery,
  useLazyGetProjectsQuery,
} = projectApi;
